import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import PreviewCompatibleImage from './../components/PreviewCompatibleImage'

import Layout from '../components/Layout'

import podcastAngleSVG from '../img/podcast-angle-bg.svg'

import Markdown from 'markdown-to-jsx'
import ContactPopup from '../components/ContactPopup'

export const LeadyourworkPageTemplate = ({
  leadyourworkprofilepicture,
  leadyourworkprimaryheadline,
  leadyourworkdescription,
  bravespaceboxes,
  leadyourworkstatement,
  bravewhymatter,
  bravestthing,
  options,
  humanblock,
  perfectforyou,
}) => {
  const [popupConfig, setPopupConfig] = React.useState({})
  const [popupButton, setPopupButton] = React.useState('')

  return (
    <>
      {JSON.stringify(popupConfig) === '{}' ? null : (
        <ContactPopup
          callback={(e) => {
            if (e === false) {
              setPopupConfig({})
            }
          }}
          cc={popupConfig}
          buttonText={popupButton}
        />
      )}
      <div>
        {/* profilepicture & primaryheadline */}
        <div className="w-100 gc-podcast grid leadyourwork-pt-ns">
          <div className="gcs2-ns gcs1 gce5 grs1 gre2 z-2 mb-1px">
            <PreviewCompatibleImage
              imageInfo={{
                image: leadyourworkprofilepicture.image,
                alt: leadyourworkprofilepicture.alt,
              }}
            />
          </div>
          <div
            className="db-ns dn gcs1 gce5 grs1 gre2 z-3 bg-100wh"
            style={{ backgroundImage: `url(${podcastAngleSVG})` }}
          ></div>
          <div className="gcs1 gce3-ns gce5 grs1-ns grs2 gre2-ns gre3 pt4 pt0-ns g-self-end lh-copy z-4">
            <div className="w-100 pl5-ns pr3-ns ph3 pb3-l">
              <div className="tr-l tc pb4 ski-dark-turquoise tracked f2-l f3-ns f3 ph3-l">
                Signature Program
              </div>
              <div className="tl-ns tc fw5 f4-l f5 ski-dark-silver tracked-mega pr3-m">
                {leadyourworkprimaryheadline.title}
              </div>
              <br />
              <div className="pb4 fw4 tl-ns tc f5 lh-copy tracked pt1-markup">
                <Markdown>{leadyourworkprimaryheadline.description}</Markdown>
              </div>
            </div>
          </div>
        </div>

        {/* // silver line  */}
        <div className="dn-ns db w-100 pv3 ph7-ns ph5 f4 ski-verylight-blue tracked">
          <div className="bb bw2"></div>
        </div>

        {/* // leadyourworkdescription  */}
        <div className="tc pt4 pb4-ns pb2 ph6-ns ph3-m ph2 ski-medium-turquoise">
          <div className="f2-l f2-ns f3 tracked">
            {leadyourworkdescription.description}
          </div>
        </div>

        {/* // Brave Space Boxes  */}
        <div className="gafc grid tc pt2-ns pb2 pb4-ns ph6-l ph2-sm-i ph3 gc4 gafr ">
          {/* l top */}
          <div className="gcs1 gce2-ns gce5 grs1 gre2 ph4 pt4 pb0 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bt-ns">
            <PreviewCompatibleImage
              imageInfo={{
                image: bravespaceboxes.leftimage_pb,
                alt: bravespaceboxes.leftalt,
              }}
            />
          </div>
          {/* l bottom */}
          <div className="gcs1 gce2-ns gce5 grs2 gre3 ph4-l ph2-m ph4 pt0 pb4-ns pb2 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bb-ns items-center">
            <div className="pt3-markup no-hr pt3-l pt0-m pt4 tc fw4 tracked f5 lh-copy ph3-l ph2-m ph3 dc-sm-i">
              <Markdown>{bravespaceboxes.leftdescription}</Markdown>
            </div>
          </div>
          {/* ml top */}
          <div className="gcs2-ns gcs1 gce3-ns gce5 grs1-ns grs3 gre2-ns gre4 ph4 pt4 pb0 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bt-ns">
            <PreviewCompatibleImage
              imageInfo={{
                image: bravespaceboxes.middleleftimage,
                alt: bravespaceboxes.middleleftalt,
              }}
            />
          </div>
          {/* ml bottom */}
          <div className="gcs2-ns gcs1 gce3-ns gce5 grs2-ns grs4 gre3-ns gre5 ph4 pt0 pb4-ns pb2 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bb-ns items-center">
            <div className="pt3-markup no-hr pt4-l pt3-m pt4 tc fw4 tracked f5 lh-copy ph3 dc-sm-i">
              <Markdown>{bravespaceboxes.middleleftdescription}</Markdown>
            </div>
          </div>
          {/* mr top */}
          <div className="gcs3-ns gcs1 gce4-ns gce5 grs1-ns grs5 gre2-ns gre6 ph4 pt4 pb0 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bt-ns">
            <PreviewCompatibleImage
              imageInfo={{
                image: bravespaceboxes.middlerightimage,
                alt: bravespaceboxes.middlerightalt,
              }}
            />
          </div>
          {/* mr bottom */}
          <div className="gcs3-ns gcs1 gce4-ns gce5 grs2-ns grs6 gre3-ns gre7 ph4 pt0 pb4-ns pb2 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bb-ns items-center">
            <div className="pt3-markup no-hr pt4-l pt3-m pt4 tc fw4 tracked f5 lh-copy ph3 dc-sm-i">
              <Markdown>{bravespaceboxes.middlerightdescription}</Markdown>
            </div>
          </div>
          {/* r top */}
          <div className="gcs4-ns gcs1 gce5 grs1-ns grs7 gre2-ns gre8 ph4 pt4 pb0 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bt-ns">
            <PreviewCompatibleImage
              imageInfo={{
                image: bravespaceboxes.rightimage,
                alt: bravespaceboxes.rightalt,
              }}
            />
          </div>
          {/* r bottom */}
          <div className="gcs4-ns gcs1 gce5 grs2-ns grs8 gre3-ns gre9 ph4 pt0 pb4-ns pb2 grid b--ski-medium-turquoise-ns bw1 bl-ns br-ns bb-ns items-center">
            <div className="pt3-markup no-hr pt3-l pt0-m pt4 tc fw4 tracked f5 lh-copy ph3 dc-sm-i">
              <Markdown>{bravespaceboxes.rightdescription}</Markdown>
            </div>
          </div>
        </div>

        {/* // silver line  */}
        <div className="dn-ns db w-100 pv3 ph7-ns ph5 f4 ski-verylight-blue tracked">
          <div className="bb bw2"></div>
        </div>
        
        {/* leadyourwork statement */}
        <div className="tc pv3 ph7-ns ph5-m ph4">
          <div className="lh-copy tracked ttc fw4 f4 f3-ns ski-dark-turquoise pt3-markup">
            <Markdown>{leadyourworkstatement.statement}</Markdown>
          </div>
        </div>

        {/* Bravestthing */}
        <div className="tc pt2-ns pb4 ph6-l ph5-m ph4 mt4-ns bg-ski-light-blue">
          <div className="white-90 ttc f3-ns f3 fw6 tracked-mega pv4">
            {bravestthing.heading}
          </div>
          <div className="grid gc2">
            <div className="gcs1 gce2-l gce3 grs2-l grs1 gre3-l gre2 pr3-ns pb0-ns pb4 pt2">
              <PreviewCompatibleImage
                imageInfo={{
                  image: bravestthing.image,
                  alt: bravestthing.alt,
                }}
              />
            </div>
            <div className="gcs2-l gcs1 gce3 grs2-ns gre3-ns grs3 gre4 pl4-l grid pt3-m">
              <div className="self-center pt3-m tj-l f5-ns f5 fw4 pt3-markup lh-copy ph0-ns ph4-m ph2">
                <div className="tj-l tc lh-copy pt2-markup">
                  <Markdown>{bravestthing.description}</Markdown>
                  <br />
                  <button
                    className="grow pointer center link tc fw4 f6 ph5-ns tracked ml-auto mr0 ph3 pv2 bg-ski-verylight-blue bn shadow-1"
                    onClick={(event) => {
                      event.preventDefault()
                      setPopupButton('Send')
                      setPopupConfig({
                        fullName: 1,
                        email: 2,
                        interest: 3,
                        timezone: 4,
                        heading: 'I am ready to be brave',
                      })
                    }}
                  >
                    Show me how big your brave is
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* // brave: why matter */}
        <div className="mt5-ns mt3 w-100 podcast-grid grid ph6-l ph3-m">
          <div className="gcs1 gce2-ns gce3 grs1-ns grs2 gre2-ns gre3 bg-ski-medium-blue grid">
            <div className="pt5-ns pt4-m tc-m pt4 z-2 self-center">
              <div className="ski-dark-blue ttc f2-ns f3 fw6 tracked tr-ns tc tc-m ph3-m pr5-ns pb5-ns pb4-m pb4">
                {bravewhymatter.heading}
              </div>
              <div className="tracked no-hr tl-ns tc-m tc pl5-ns pl4 pr4 pr6-ns pr5-m f5-ns f5 f5-m pb5-ns pb4 pb4-m lh-copy">
                <Markdown>{bravewhymatter.description}</Markdown>
              </div>
            </div>
          </div>
          <div className="gcs2-ns gcs1 gce3 grs1 gre2 g-self-end z-2 self-center grid h-100">
            <PreviewCompatibleImage
              imageInfo={{
                image: bravewhymatter.image,
                alt: bravewhymatter.alt,
                imgClass: 'right-cover-force',
              }}
            />
          </div>
        </div>

        <div className="bb bw2 w6 center pb4 b--white"></div>

        {/* // options: what happens next */}
        <div className="tl mt3-ns pt4 pb4 ph6-l ph4 f4-ns f5 f5-m lh-copy bg-ski-verylight-silver">
          <div className="fw6 tc f2-ns f3 tracked pb0-ns">
            <span className="fw7 ski-medium-silver-ns ski-dark-silver">
              <Markdown>{options.heading}</Markdown>
            </span>
            <div className="fw6 f4-ns f5 lh-copy tracked pb4">
              <Markdown>{options.subheading}</Markdown>
            </div>
          </div>
          <div className="grid gra2">
            <div className="gcs1 gce2 grs1 gre2 grid gcopt_left">
              <div className="db-ns dn gcs1 gce2-ns gce3 grs1 gre2 pl2-ns pr4-l pr-2-m pb0-ns pb4 pt2 v-mid">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: options.leftimage,
                    alt: options.leftalt,
                  }}
                />
              </div>
              <div className="gcs2-ns gcs1 gce3 grs1-ns grs2 gre2-ns gre3 pr4-l grid pt3-m">
                <div className="self-center pt3-m tj f5-ns f5 fw4 pt3-markup lh-copy ph5-ns ph4-m ph3">
                  <div className="tj f5 lh-copy fw4 tracked pt4-markup-ns pt3-markup">
                    <Markdown>{options.description}</Markdown>
                  </div>
                </div>
              </div>
            </div>

            <div className="grs2 gre3 gcs1 gce2 grid gc2">
              <div className="gcs1 gcs2-ns gce3 grs1 gre2 ph5-l ph2-m pb0-ns pb4 pt4 grid-l justify-content-l v-mid">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: options.rightimage,
                    alt: options.rightalt,
                  }}
                />
              </div>
              <div className="gcs1 gce2-ns gce3 grs1-ns grs2 gre2-ns gre3 pl4-l grid pt4-ns">
                <div className="pt3-m f5-ns f5 fw4 pt3-markup lh-copy ph0-ns ph4-m ph3 self-center">
                  <div className="f5 lh-copy fw5 tracked pt2-markup">
                    <div className="w-100 fw6 pt3-ns pb4">
                      <Markdown>{options.secondheading}</Markdown>
                    </div>
                    <span className="list pt4-markup fw4">
                      <Markdown>{options.list}</Markdown>
                    </span>
                  </div>
                  <div className="grid w-100 pt4">
                    <button
                      className="grow pointer center link tc fw4 f6 ph5-ns tracked ml-auto mr0 ph3 pv2 bg-ski-medium-blue bn shadow-1"
                      onClick={(event) => {
                        event.preventDefault()
                        setPopupButton('Send')
                        setPopupConfig({
                          fullName: 1,
                          email: 2,
                          interest: 3,
                          timezone: 4,
                          heading: 'I am done with that',
                        })
                      }}
                    >
                      I officially declare I'm done with that.
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Being Human: prepping for tomorrow */}
        <div className="mt4">
          {/* imgbackground title block */}
          <div className="tc pt4-ns pt0 ph0">
            <div className="grid">
              <div className="gcs1 gce2 grs1 gre2-ns gre3 z-1 w100vw h-100 h-inherit grid">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: humanblock.backgroundimage,
                    alt: 'Vision',
                    imgClass: 'maxh-7-ns cover h-inherit',
                  }}
                />
              </div>
              <div className="gcs1 gce2 grs1 gre3 ph6-ns ph5-m ph4 z-4 grid justify-center w100vw pv2">
                <div className="self-center-ns w-fit-content-ns pv3-ns ph5-ns ph4 bg-white-40">
                  <div className="db-ns dn self-center cursive f1-l f2-m f3 fw5 w-fit-content ski-medium-turquoise pv3-ns ph4">
                    {humanblock.title}
                  </div>
                  <div className="f5 fw4 tracked-mega pt4-markup pt0-ns pt3 pre-ns lh-copy pb3">
                    <Markdown>{humanblock.subtitle}</Markdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tracked grid gafr">
            <div className="ph6-ns ph5-m ph4 bg-ski-medium-blue pt4">
              <div className="pt3-markup no-hr tc fw4 f5-ns f5 lh-copy pb3 pt3-markup">
                <Markdown>{humanblock.description}</Markdown>
              </div>
            </div>
            <div className="ph6-ns ph5-m ph4 bg-ski-medium-blue">
              <div className="pt3-markup no-hr tc fw5 f3-ns f5 lh-copy pb4-ns pb3 ph5-ns ski-medium-turquoise-ns ski-dark-turquoise ph3-m pt3-markup">
                <Markdown>{humanblock.highlight}</Markdown>
              </div>
            </div>
            <div className="ph0 grid gr6">
              <div className="gcs1 gce2 grs1 gre5 bg-ski-medium-blue"></div>
              <div className="gcs1 gce2 grs1 gre7 ph3-ns pb4 pt2">
                <div className="ph6-l ph5-m ph3 center mw8">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: humanblock.bottomimage,
                      alt: humanblock.bottomalt,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="ph6-ns ph5-m ph4 pb3-ns pb4">
              <div className="ski-medium-green pt3-markup no-hr tc fw4-ns fw6 f3-ns f5 lh-copy pb3-ns ph5-ns ph3-m">
                <Markdown>{humanblock.end}</Markdown>
              </div>
            </div>
          </div>
        </div>

        {/* Perfect for You */}
        <div className="grid gafr w100vw">
          <div className="grs1 gre2 gcs1 gce2 pr2-ns ph4 pl7-l pl4-m pt4 pb0-ns pb3 bg-ski-light-turquoise">
            <div className="ski-dark-turquoise f2-ns f3 w-100 fw5 tracked tl-ns tc">
              {perfectforyou.title}
            </div>
            <div className="grid if-you-grid-ns pt3-ns pt4">
              <div className="gcs1 gce2 grs1 gre2">
                <div className="cursive ski-dark-turquoise-ns f2-ns f3 tracked pl4-ns">
                  {perfectforyou.leading}
                </div>
              </div>
              <div className="gcs2-ns gcs1 gce3-ns gce2 grs1-ns grs2 gre2-ns gre3 pb4">
                <div className="pt2-markup-ns pt3-markup lh-copy tracked pt2-ns pt4 pr3-ns pl0-ns pl4">
                  <Markdown>{perfectforyou.list}</Markdown>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w100vw grid gr2 grs2 gre3 gcs1 gce2">
            <div className="bg-ski-light-turquoise grs1 gre2 gcs1 gce2"></div>
            <div className="grs1 gre3 gcs1 gce2 grid pv2">
              <button
                className="grow pointer center link tc fw4 f6 ph5 tracked ml-auto mr0 ph3 pv2 bg-ski-dark-blue bn shadow-1"
                onClick={(event) => {
                  event.preventDefault()
                  setPopupButton('Send')
                  setPopupConfig({
                    fullName: 1,
                    email: 2,
                    message: 3,
                    heading: 'Tell me more',
                  })
                }}
              >
                Tell me more
              </button>
            </div>
          </div> */}
        </div>

        <div className="mh6-ns mh4-m mh2 b--ski-medium-turquoise bw1 ba ph5-ns ph2-m ph4 pv3 mb4 mt4 grid tracked f4z">
          <div className="grs1 gre2 gcs1 gce2 grid pv2">
            <button
              className="white-80 cursive f2-ns f3 fw2 tracked pb2 shadow-1 grow pointer link ph4 pv2 ba dt center self-center no-underline bg-ski-medium-turquoise-ns bg-ski-dark-blue b--ski-medium-turquoise-ns b--ski-dark-blue"
              onClick={(event) => {
                event.preventDefault()
                setPopupButton('Send')
                setPopupConfig({
                  fullName: 1,
                  email: 2,
                  interest: 3,
                  timezone: 4,
                  message: 5,
                  heading: 'Book a Consultation',
                })
              }}
            >
              Book a Consultation
            </button>
          </div>
          <div className="gcs1 gce2 grs2 gre3 tc pv3">
            Individual Group Sessions and Team Workshops available
          </div>
        </div>
      </div>
    </>
  )
}

LeadyourworkPageTemplate.propTypes = {
  leadyourworkprofilepicture: PropTypes.object,
  leadyourworkprimaryheadline: PropTypes.object,
  leadyourworkdescription: PropTypes.object,
  bravespaceboxes: PropTypes.object,
  leadyourworkstatement: PropTypes.object,
  bravewhymatter: PropTypes.object,
  bravestthing: PropTypes.object,
  options: PropTypes.object,
  humanblock: PropTypes.object,
  perfectforyou: PropTypes.object,
}

const leadyourworkPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      seo={{
        title: 'Lena Ski - Lead Your Work',
        keywords: [`branddesign`, `purposework`, `workplaceculture`],
        description:
          'Business coaching program in South Africa. Developing sustainability-driven strategies and innovative ideas that will pioneer change',
      }}
      page="leadyourwork"
    >
      <LeadyourworkPageTemplate
        leadyourworkprofilepicture={frontmatter.leadyourworkprofilepicture}
        leadyourworkprimaryheadline={frontmatter.leadyourworkprimaryheadline}
        leadyourworkdescription={frontmatter.leadyourworkdescription}
        bravespaceboxes={frontmatter.bravespaceboxes}
        leadyourworkstatement={frontmatter.leadyourworkstatement}
        bravewhymatter={frontmatter.bravewhymatter}
        bravestthing={frontmatter.bravestthing}
        options={frontmatter.options}
        humanblock={frontmatter.humanblock}
        perfectforyou={frontmatter.perfectforyou}
      />
    </Layout>
  )
}

leadyourworkPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default leadyourworkPage

export const pageQuery = graphql`
  query LeadyourworkPageTemplate {
    markdownRemark(fileAbsolutePath: { regex: "/leadyourwork/" }) {
      frontmatter {
        leadyourworkprofilepicture {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
        }
        leadyourworkprimaryheadline {
          description
          title
        }
        leadyourworkdescription {
          description
        }
        leadyourworkstatement {
          statement
        }
        bravespaceboxes {
          leftimage_pb {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          leftalt
          leftdescription
          middleleftimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          middleleftalt
          middleleftdescription
          middlerightimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          middlerightalt
          middlerightdescription
          rightimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          rightalt
          rightdescription
        }
        bravewhymatter {
          heading
          description
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
        }
        bravestthing {
          heading
          description
          image {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          alt
        }
        options {
          heading
          subheading
          description
          secondheading
          list
          leftimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          leftalt
          rightimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          rightalt
        }
        humanblock {
          title
          subtitle
          description
          highlight
          end
          backgroundimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          bottomimage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                aspectRatio
                src
                srcSet
                sizes
                srcWebp
                srcSetWebp
              }
            }
          }
          bottomalt
        }
        perfectforyou {
          title
          leading
          list
        }
      }
    }
  }
`
